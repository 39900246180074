.header{
  display: flex;
  justify-content: center;
}

.header-logo{
  background-color: #440A6D;
}
.header-logo-img{
  width: 50%;
}
.content-container{
  background-color: #151937;
}
.landing-text-heading{
 color:white;
 font-family: "Poppins";
}
.landing-text{
  color:white;
  font-family: "Poppins";
}

.claim-pi-btn-container{
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 500;
  text-transform: None;

  letter-spacing: 0em;
  color: #FFF;
  background-color: #ff5ea3;
  border-style: none;
  color: #FFF!important;
  cursor: pointer;
  padding: 15px 10px 15px 10px;
  border-radius: 7px 7px 7px 7px;
}
.claim-pi-btn-container:hover{
  
  background-color: rgb(247, 42, 131);
 padding-left: 12px;
 padding-right: 12px;
 font-size: 18px;
}
.childwithcoin-img{
  width: 70%;
  max-width: 500px;
}
.random-mid-content-card-body{
  background-color: #151937;
}
.wallet-img{
  width: 50%;
  max-width: 500px;
}
.random-mid-content-card-body-text{
  color: #FFF;
  font-family: "Poppins";
}
.bottom-content{
  font-family: "Poppins", Sans-serif;
  color:white
}
.bottom-content-trust-text{
  color:white;
  font-family: "Poppins", Sans-serif;
}
.download-text{
  color:rgba(255, 255, 255, 0.6);
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: None;
  line-height: 1em;
  letter-spacing: 0em;
}
.download-container{
  flex-direction: column;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: None;
  line-height: 1em;
  letter-spacing: 0em;
  color: white;
}

.footer-background-overlay{
  background-color: transparent;
    background-image: radial-gradient(at center bottom, rgb(178, 101, 255) 0%, rgba(14, 39, 128, 0.03) 74%);
    opacity: 0.3;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    position: absolute;
    width: 100%;
    padding: 100px;
    bottom: 0px;
}
.download-text{
  color:rgba(255, 255, 255, 0.6);
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: None;
  line-height: 1em;
  letter-spacing: 0em;
}
.email-subscribe{
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.btn-subscribe{
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: None;
  line-height: 1em;
  letter-spacing: 0em;
  color: #FFF;
  background-color: #ff5ea3;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-right: 15px;
  padding-left: 15px;
  height: 100%;
}
.btn-subscribe:hover{
  
  background-color: rgb(247, 42, 131);
  color: #FFF;
}
.subscribe-text{
  color:rgba(255, 255, 255, 0.6)!important;
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.footer{
  font-family: "Poppins", Sans-serif;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #000000e8;
  color: white;
}
.swal2-title{
  font-family: "Poppins", Sans-serif;
}
.swal2-html-container{
  font-family: "Poppins", Sans-serif;
}
.swal2-confirm{
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
}
.modal-title{
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
  color: #FFF;
}
.modal .btn-close{
  color: #FFF!important;
}
.modal .passphrase-input{
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  min-height: 200px;
}
.btn-close-passphrase{
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: None;
  line-height: 1em;
  letter-spacing: 0em;
  color: #FFF;
  background-color: #5e69ff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
 padding: 15px 15px 15px 15px;
 
}

.btn-close-passphrase:hover{
  
  background-color: rgb(247, 42, 131);
  color: #FFF;
}

.modal-content{
  background-image: radial-gradient(at top left, #562080 0%, #251D46 100%);
  border-style: none;

  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
}
/* Maintenance Page Styles */
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #6e48aa, #9d50bb);
  color: #fff;
  font-family: 'Arial', sans-serif;
  text-align: center;
  overflow: hidden;
}

.maintenance-content {
  max-width: 600px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.maintenance-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  animation: fadeIn 2s ease-in-out;
}

.maintenance-text {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  animation: fadeIn 3s ease-in-out;
}

.maintenance-animation {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 2rem;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out;
}

.purple {
  background: #6e48aa;
}

.pink {
  background: #ff6b6b;
  animation-delay: 0.2s;
}

.blue {
  background: #4ecdc4;
  animation-delay: 0.4s;
}

.maintenance-contact {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.maintenance-contact a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.maintenance-contact a:hover {
  text-decoration: underline;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}